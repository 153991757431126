// Function to set a cookie
function setCookie(name, value) {
  document.cookie = `${name}=${encodeURIComponent(value)}`;
}

// Function to get a cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
  return '';
}

// Function to get URL parameters
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Function to add or update URL parameter
function updateUrlParameter(url, param, value) {
    const regex = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    
    if (url.match(regex)) {
        return url.replace(regex, '$1' + param + '=' + value + '$2');
    } else {
        return url + separator + param + '=' + value;
    }
}

// Handle UTM parameter persistence
function handleUtmPersistence() {
  const utmParams = ['utm_source', 'utm_medium'];
  
  utmParams.forEach(param => {
    const value = getUrlParameter(param);
    if (value) {
      // Save to sessionStorage if UTM is present in URL
      setCookie(param, value)
    } else {
      // Check if UTM exists in sessionStorage and add to URL
      const storedValue = getCookie(param);
      if (storedValue && window.location.pathname.endsWith("/vacatures/solliciteer/")) {
        const newUrl = updateUrlParameter(window.location.href, param, storedValue);
        window.history.replaceState({}, '', newUrl);
      }
    }
  });
}

// Initialize
document.addEventListener('DOMContentLoaded', function() {
    handleUtmPersistence();
});